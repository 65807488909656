    import axios from 'axios';
    import Cookies from 'js-cookie';

    const API_ENDPOINT = 'https://col.barbace.nl/ta/c';

    interface UserParams {
        firstName?: string;
        lastName?: string;
        email?: string;
        phone?: string;
    }

    declare global {
        interface Window {
            Cookiebot: {
                consent: {
                    marketing: boolean;
                };
            };
        }
    }

    export const trackEvent = async ({ eventType, userParams }: Props) => {
        if (typeof window !== 'undefined' && window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.marketing) {
            const payload: { [key: string]: any } = {
                et: eventType,
                cid: process.env.GATSBY_COL_CONFIGURATION_ID,
                fu: window.location.href
            };

            const userID = Cookies.get('uid');
            if (userID) payload.uid = userID;

            if (userParams?.firstName) payload.ufn = userParams.firstName;
            if (userParams?.lastName) payload.uln = userParams.lastName;
            if (userParams?.email) payload.uem = userParams.email;
            if (userParams?.phone) payload.uph = userParams.phone;

            try {
                const response = await axios.post(API_ENDPOINT, payload);
                if (response.data?.uid) {
                    Cookies.set('uid', response.data.uid, { expires: 365, secure: true, sameSite: 'Strict' });
                }
            } catch (error) {
                console.error('Error sending event to API:', error);
            }
        }
    };

    interface Props {
        eventType: 'page_view' | 'lead' | 'contact' | 'complete_registration' | 'schedule';
        userParams?: UserParams;
    }